export function displayMonth(n) {
    var varn = n?.toString()
    if (varn == "1") return "Jan"
    if (varn == "2") return "Feb"
    if (varn == "3") return "Mar"
    if (varn == "4") return "Apr"
    if (varn == "5") return "May"
    if (varn == "6") return "Jun"
    if (varn == "7") return "Jul"
    if (varn == "8") return "Aug"
    if (varn == "9") return "Sep"
    if (varn == "10") return "Oct"
    if (varn == "11") return "Nov"
    if (varn == "12") return "Dec"
    return "Unknown"
}
export default function ButtonSecondary({
    classColor
    ,classSize=`text-xl w-48 px-4 py-2 `
    ,onClick
    ,message
    ,icon
    ,hasIcon=true
}) {
    return (<>
                 <div className={`flex-0 flex items-center content-center `}>
                        <div className="">
                          <div className={`${classColor} 
                            ${classSize}
                            font-bold  rounded-md shadow cursor-pointer text-center 
                             block 
                            
                            `}
                            onClick={onClick}
                          >
                            <div className="flex items-center content-center">
                              {hasIcon &&
                              <div className="flex-0 mr-2">
                                {icon}
                              </div>
                              }
                              <div className="flex-0">
                                {message}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
            </>)
}
import { agoDelta } from "@/lib/utils/ago";
import { stripZero } from "@/lib/utils/stripZero";



function ChatTimer({
     start_date
    ,timestamp
    ,time
}) {

    const vstart_date = + new Date(start_date)
    const vtimestamp = + new Date(timestamp)

    
    return (
        <div 
            className="whitespace-nowrap "
        >
            <div>
                {stripZero(
                        agoDelta(
                            Math.round((( vtimestamp-vstart_date)) + (time*1000))/1000 
                            ,vstart_date
                            )[0]
                        )}
            </div>
            
        </div>
        )

}

export default ChatTimer;
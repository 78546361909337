export function ago(date,timestamp=+new Date) {
    
    const thisdate = +new Date(date)

    // Make a fuzzy time
    var delta = Math.round((+new Date(timestamp) - thisdate) / 1000);

    return agoDelta(delta,date)

}

export function agoDelta(delta,date) {

    var  minute = 60
        ,hour = minute * 60
        ,day = hour * 24
        ,week = day * 7
        ,month = week * 4
        ,year = month * 12

    if (delta < 1) return [`0s`,`0 second ago`,humandate(date),delta];
    if (delta < minute)  return [`${Math.round(delta)}s`,`${Math.round(delta)} seconds ago`,humandate(date),delta];
    if (delta < 2 * minute) return [`1m`,`1 minute ago`,humandate(date),delta];
    if (delta < hour)  return [`${Math.floor(delta / minute)}m`,`${Math.floor(delta / minute)} minutes ago`,humandate(date),delta];
    if (Math.floor(delta / hour) == 1) return [`1h`,`1 hour ago`,humandate(date),delta];
    if (delta < day) return [`${Math.floor(delta / hour)}h`,`${Math.floor(delta / hour)} hours ago`,humandate(date),delta];
    if (delta < day * 2) return [`1d`,`1 day ago`];
    if (delta < week) return [`${Math.floor(delta / day)}d`,`${Math.floor(delta / day)} days ago`,humandate(date),delta];
    if (delta < week * 2) return [`1w`,`1 week ago`,humandate(date),delta];
    if (delta < month) return [`${Math.floor(delta / week)}w`,`${Math.floor(delta / week)} weeks ago`,humandate(date),delta];
    if (delta < month * 2) return [`1mo`,`1 month ago`,humandate(date),delta];
    if (delta < year) return [`${Math.floor(delta / month)}mo`,`${Math.floor(delta / month)} months ago`,humandate(date),delta];
    if (delta < year * 2) return [`1y`,`1 year ago`,humandate(date),delta];
    if (delta > year * 2) return [`${Math.floor(delta / year)}y`,`${Math.floor(delta / year)} years ago`,humandate(date),delta];
    return ["NaN","NaN","NaN"]
}


export function humandate(n) {
    const event = new Date(n);
    return (event.toUTCString());
  }


  export function deltaDiff(start_date,end_date=+new Date) {
    
    const thisdate = +new Date(start_date)

    // Make a fuzzy time
    var delta = Math.round((+new Date(end_date) - thisdate) / 1000);

    return delta

}
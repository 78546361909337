

import Link from "next/link"
import * as React from "react"
import BroadcastMenuOptions from "./broadcast-menu-options"
import { hasData } from "@/lib/utils/hasData"
import { config, player_style } from "@/lib/config"
import _ from 'underscore';
import SvgJsx from "@/components/templateux/svg/svg-jsx"
import { openWindow } from "@/lib/utils/openWindow"
import RotaryLogo from "@/components/templateux/rotary-logo"
import ToggleButton from "@/components/templateux/toggle/toggle-button"
import { timerFormat } from "@/lib/utils/timerFormat"
import { countUpBySeconds } from "@/lib/utils/countUp"
import Slider from 'react-input-slider';
import LoadingRotary from "@/components/templateux/loading/loading-rotary"
import { getExtention } from "@/lib/utils/getExtention"
import { getAvatarUrl } from "@/lib/utils/getAvatarUrl"
import isAdminUser from "@/lib/utils/isAdminUser"
import ChatTimer from "../chat/chat-timer"
import AuthorCredit from "@/components/templateux/user/author-credit"

export default function BroadcastSingleRow({ 
               itemdata
              ,userdata
              ,display_view
              ,openModal
              ,closeModal
              ,cache
              ,set_cache
              ,navtype
              ,helptype
              ,display_data
              ,getWeekday
              ,getNormalTime
              ,all_folks
              ,player_state
              ,set_player_state
              ,doSeekbyRatio
              ,permaurl
              ,broadcast_date
              ,toggle
              ,togglePublish
              ,session
              ,playerControls
              ,post_override=false
              ,isPlaying
              ,set_isPlaying
              ,isShowSeek
              ,set_isShowSeek
             }) {



    return (
      <div className=" w-full inline-block ">


        <div className='flex items-center content-center'>

          {/* ROW CONTENT */}
          <div className={` flex-1  border  rounded-md flex items-center content-center
                  ${isPlaying
                  ? 'border-gray-500 shadow-md'
                  : 'border-gray-700'}
          `}>

            {/* PLAYHEAD CONTAINER */}
            <div className="flex-0">

                    {/* COMPLETED */} 
                      {(post_override || (display_data?.bol_status?.toString() !== "0" && ["1" , "2"]?.indexOf(display_data?.bol_recording?.toString()) > -1)) &&
                      <>
                        <div
                          style={!isPlaying 
                            ?   { //not selected
                                      backgroundImage: `url("/images/app/pause-gray-64x64.png")`
                                      ,backgroundRepeat: 'no-repeat'
                                      ,backgroundPosition: 'center'
                                      ,backgroundSize:'cover' //'80px' 
                                    } 
                            :  player_state?.isPlaying 
                              ? { //is playing
                                  backgroundImage: `url("/images/app/live-64x64.gif")`
                                  ,backgroundRepeat: 'no-repeat'
                                  ,backgroundPosition: 'center'
                                  ,backgroundSize:'cover' //'80px' 
                                } 
                              : { //is paused
                                      backgroundImage: `url("/images/app/pause-blue-64x64.png")`
                                      ,backgroundRepeat: 'no-repeat'
                                      ,backgroundPosition: 'center'
                                      ,backgroundSize:'cover' //'80px' 
                                    } 
                            }
                            className='w-20 h-20 rounded-l-md'
                        >
                          <div 
                            style={{
                              backgroundImage: `url("${getAvatarUrl(
                                display_data?.avatar_url
                                ,display_data?.avatar_url_sfw
                                ,display_data?.bol_nsfw
                                ,display_data?.avatar_crdate
                                ,''
                                ,display_data?.id
                                )}")`
                              ,backgroundRepeat: 'no-repeat'
                              ,backgroundPosition: 'center'
                              ,backgroundSize:'cover' 
                            }}
                            className={` flex items-center content-center cursor-pointer  w-20 h-20 rounded-l-md 
                            ${isPlaying
                              ? ''// `bg-red-600 hover:bg-red-500 text-white`
                              : '' //`bg-gray-500 hover:bg-gray-400 text-white ` //
                            }
                                        `}
                          onClick={()=> {
                            playerControls();
                            set_isShowSeek(true);
                          }}
                          >
                            
                            <div className={`
                              text-white flex-1 w-20 h-20 rounded-l-md flex items-center content-center justify-center
                              ${isPlaying
                                ? 'bg-[#00000055] hover:bg-[#00000033]'
                                : 'bg-[#00000099] hover:bg-[#00000077]'
                              }
                              `}>
                            
                            <div
                              title={player_state?.isEnded && isPlaying
                                ? "Restart recording"
                                : player_state?.isPlaying  && isPlaying
                                  ? "Pause"  
                                  : "Play" 
                              }

                              
                            >
                            
                                <SvgJsx 
                                    type={'fill'}
                                    icon={player_state?.isEnded && isPlaying
                                            ? "refresh-sm"
                                            : player_state?.isPlaying  && isPlaying
                                              ? "pause-sm" 
                                              : "play-sm"
                                          }
                                    className={`h-12 w-12  mx-auto rounded-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)]`}
                                    title={!player_state?.isPlaying ? "play" : "pause"}
                                />
                            
                              
                            </div>
                              
                            
                            </div>
                            
                          </div>
                        </div>
                      </>
                      }

                    {/* DRAFTS */}
                    {display_data?.bol_status?.toString() == "0" &&
                      <>
                      <div className={`flex items-center content-center cursor-pointer  w-20 h-20 rounded-l-md 
                              bg-brand-dark hover:bg-brand-darkest text-white group
                                  
                                    `}
                                    onClick={()=> {
                                      if (typeof window !== undefined) {
                                          openWindow(`${config.broadcast.app_base_url}?broadcast=${display_data?.content_id}`, config.broadcast.title, window, config.broadcast.width, config.broadcast.height);
                                      }
                                    }}     
                                    title={`Launch Broadcaster App`}       
                        >
                        <div className="flex-1 mx-auto justify-center flex  ">
                        
                        
                          <RotaryLogo 
                            classSize='w-12 h-12 mx-auto '
                            classAnimate=' animate-spin-slow'
                            bgColor='rgba(38,38,220,0)'
                            centerColor='rgba(38,38,220,1)'
                            pointerColor='#ff0000'
                            holeColor='rgb(38,38,220)'
                            dialerColor='#ffffff'
                            size='64'
                          />
                          
                        
                        </div>
                        
                      </div>
                      </>
                      }

                    {/* PLACEHOLDER */}
                    {display_data?.bol_status?.toString() == "-1" &&
                      <>
                      <div className={`h-16 flex items-center content-center justify-center w-full rounded-t-md
                              bg-gray-600  text-white group
                                  
                                    `}
                                    onClick={()=> {
                                    alert('Still loading honey. Calm yer jets')
                                    }}     
                                    title={`Loading`}       
                        >
                        <div className="flex-1 mx-auto justify-center flex  ">
                        
                        
                          <LoadingRotary 
                              classSize={`w-12 h-12 mx-auto block`}
                              classAnimate='animate-spin-slow'
                              bgColor='#00000000'
                              centerColor='#000000'
                              pointerColor='#c0c0c0'
                              holeColor='#000000aa'
                              dialerColor='#c0c0c0aa'
                              size='64'
                          />
                        
                        </div>
                        
                      </div>
                      </>
                      }

            </div>

            {/* INFO CONTAINER */}
            <div className="flex-1 ml-4 mr-2">

                {/* TOP ROW */}
                <div className="flex items-center content-center">

                      {/* TITLE */}
                      <div className="flex-1 text-left w-24 xs:w-48 overflow-hidden truncate">
                                <Link href={permaurl}>

                                  <h3 className={`text-lg font-bold `}>
                                    {hasData(display_data?.broadcast_title) ? display_data?.broadcast_title : 'Untitled show'}
                                  </h3>

                                </Link>
                      </div>





                </div>

                {/* BOTTOM ROW */}
                <div className="flex items-center content-center text-left">

                    {/* USERNAME */}
                    <div className="flex-0 flex items-center content-center text-left">

                      
                      <div className="flex-0 flex items-center content-center   h-8">
                        <Link href={`/${display_data?.user_name?.toLowerCase()}`} >

                          <AuthorCredit 
                                  display_data={display_data}
                                  isPlaying={(player_state?.isPlaying && isPlaying)}
                            />

                        </Link>
                      </div>
                      

                      
                      {JSON.parse(display_data?.guests_json || '[]')?.length > 0 && 
                      <div className="flex-0 ml-1 cursor-pointer hover:opacity-75 "
                      onClick={()=>openModal(display_data?.content_id,"info")}
                      >
                        +{JSON.parse(display_data?.guests_json || '[]')?.length}
                      </div>}

                      <div className="hidden xs:block
                      flex-0  pl-4 border-l ml-4 border-gray-700">
                      {timerFormat(countUpBySeconds(Math.ceil(display_data?.duration)))}
                      </div>

                      {/* AIR DATE */}
                      <div className="hidden sm:block
                      flex-0  pl-4 border-l ml-4 border-gray-700">
                        
                        <ChatTimer
                            start_date={new Date(display_data?.start_date)?.toString()}
                            timestamp={(display_data?.timestamp)?.toString()}
                            time={0}
                        />
                      </div>

                    </div>

                    


                </div>



            </div>

            {/* PUBLISH */}
            {!post_override &&
            <div className="flex-0">

                                                              {/* OWNER / STAFF */}
                                                              {isAdminUser(
                                itemdata?.user_id?.toString()          //user_id
                              ,itemdata?.bol_staff_help    //bol_staff_help
                              ,itemdata?.staff_json        //staff_json
                              ,itemdata?.bol_admin_help    //bol_admin_help
                              ,userdata?.id?.toString()                            //my_id
                              ,userdata?.bol_admin?.toString()              //global_admin
                              ,helptype
                            ) &&
                            <div className="flex-1 flex">
                              
                              {display_data?.bol_status?.toString() =="0" &&
                              <div className="flex-1 flex items-center content-center text-red-500">
                                <SvgJsx 
                                    type={'fill'}
                                        
                                    icon={'lock-closed-sm'}
                                    className={`h-5 w-5 mr-1`}
                                    title={'Private'}
                                /> Private
                              </div>
                              }

                              {display_data?.bol_status?.toString() !=="0" &&
                              <div className="flex-1 flex items-center content-center h-8">

                                <div className="flex-0 flex mt-1 ml-5 pr-2">
                                  <ToggleButton
                                    bol_item={toggle}
                                    toggle_text={`Publish`}
                                    size={'small'}
                                    
                                    clickEvent={()=> togglePublish(display_data?.content_id)}
                                  />
                                </div>
                              </div>
                              }
                            </div>
                            }
              
            </div>
            }

                    
            
          </div>

         {/* MORE */}
         {!post_override &&
          <div className="flex-0 ml-1">

                <BroadcastMenuOptions 
                              item_id={display_data?.content_id}
                              item_name={display_data?.broadcast_title}
                              userdata={userdata}
                              owner_id={display_data?.user_id}
                              isAdmin={isAdminUser(
                                            itemdata?.user_id?.toString()          //user_id
                                          ,itemdata?.bol_staff_help    //bol_staff_help
                                          ,itemdata?.staff_json        //staff_json
                                          ,itemdata?.bol_admin_help    //bol_admin_help
                                          ,userdata?.id?.toString()                            //my_id
                                          ,userdata?.bol_admin?.toString()              //global_adminv
                                          ,helptype
                                        )}
                              timestamp={display_data?.timestamp}
                              session={session}
                              openModal={openModal}
                              closeModal={closeModal}
                              cache={cache}
                              set_cache={set_cache}
                              stream_url={`${display_data?.broadcast_url}${display_data?.recording_hash}${getExtention(display_data?.content_id)}`}
                              bol_status={display_data?.bol_status}
                              bol_recording={display_data?.bol_recording}
                              permaurl={permaurl}
                              player_state={player_state}

                            />
            
          </div>
          }
        </div>
        

        

                {/* LIVE SEEK */}
                {(display_data?.bol_status?.toString() =="2" 
                  && isPlaying) &&
                  <div className=" flex-1 flex items-center content-center font-thin mt-2 mr-6">

                      {/* CURRENT TIME */}
                      <div className="flex-0 mr-4 w-16 text-left">
                      {timerFormat(countUpBySeconds(Math.ceil(player_state.seek)))}
                      </div>

                      {/* SEEK */}
                      <div className="flex-1 ">

                     
                              <div className="flex items-center content-center h-4 px-1 ">

                                
                                <div className="flex-1 text-sm ">
                                <Slider
                                        axis='x'
                                        x={(player_state?.seek_slider)}
                                        xmax={100}
                                        //disabled={player_state?.isPlaying}
                                        onChange={({ x }) => {
                                                doSeekbyRatio(x)
                                            }}
                                        
                                        styles={player_style(player_state?.isPlaying)}
                                        />
                                </div>
                                
                                
                              </div>
                              
                        
                      </div>

                      {/* TOTTAL TIME */}
                      <div className="flex-0 ml-4 w-16 text-right">
                        {timerFormat(countUpBySeconds(Math.ceil(display_data?.duration)))}
                      </div>

                  </div>
                  }

      </div>
    );

   
}

  
import * as React from "react"
import _ from 'underscore';
import SvgJsx from "@/components/templateux/svg/svg-jsx"
import { timerFormat } from "@/lib/utils/timerFormat"
import { countUpBySeconds } from "@/lib/utils/countUp"
import Slider from 'react-input-slider';
import LoadingRotary from "@/components/templateux/loading/loading-rotary"
import { getAvatarUrl } from "@/lib/utils/getAvatarUrl"
import ChatTimer from "../chat/chat-timer";
import Link from "next/link";
import { hasData } from "@/lib/utils/hasData";
import AuthorCredit from "@/components/templateux/user/author-credit";
import { friendlydate } from "@/lib/utils/friendlydate";
import { IsJsonValid } from "@/lib/utils/isJsonValid";
import { textProcess } from "@/lib/utils/textprocess";

export default function BroadcastSinglePost({ 
               itemdata
              ,userdata
              ,display_view
              ,openModal
              ,closeModal
              ,cache
              ,set_cache
              ,navtype
              ,helptype
              ,display_data
              ,getWeekday
              ,getNormalTime
              ,all_folks
              ,player_state
              ,set_player_state
              ,doSeekbyRatio
              ,permaurl
              ,broadcast_date
              ,toggle
              ,togglePublish
              ,session
              ,playerControls
              ,post_override=false
              ,isPlaying
              ,set_isPlaying
              ,isShowSeek
              ,set_isShowSeek
             }) {



    return (
      <div className=" w-full inline-block ">


        <div className='flex items-center content-center'>

          {/* ROW CONTENT */}
          <div className={` flex-1  border   block sm:flex items-center content-center
                  ${isPlaying
                  ? 'border-gray-500 shadow-md'
                  : 'border-gray-700'}
          `}>

            {/* PLAYHEAD CONTAINER */}
            <div className="flex-0">

                    {/* COMPLETED */} 
                      {(post_override || (display_data?.bol_status?.toString() !== "0" && ["1" , "2"]?.indexOf(display_data?.bol_recording?.toString()) > -1)) &&
                      <>
                        <div
                          style={!isPlaying 
                            ?   { //not selected
                                      backgroundImage: `url("/images/app/pause-gray-64x64.png")`
                                      ,backgroundRepeat: 'no-repeat'
                                      ,backgroundPosition: 'center'
                                      ,backgroundSize:'cover' //'80px' 
                                    } 
                            :  player_state?.isPlaying 
                              ? { //is playing
                                  backgroundImage: `url("/images/app/live-64x64.gif")`
                                  ,backgroundRepeat: 'no-repeat'
                                  ,backgroundPosition: 'center'
                                  ,backgroundSize:'cover' //'80px' 
                                } 
                              : { //is paused
                                      backgroundImage: `url("/images/app/pause-blue-64x64.png")`
                                      ,backgroundRepeat: 'no-repeat'
                                      ,backgroundPosition: 'center'
                                      ,backgroundSize:'cover' //'80px' 
                                    } 
                            }
                            className='w-full sm:w-20 h-16 sm:h-20 '
                        >
                          <div 
                            style={{
                              backgroundImage: `url("${getAvatarUrl(
                                display_data?.avatar_url
                                ,display_data?.avatar_url_sfw
                                ,display_data?.bol_nsfw
                                ,display_data?.avatar_crdate
                                ,''
                                ,display_data?.id
                                )}")`
                              ,backgroundRepeat: 'no-repeat'
                              ,backgroundPosition: 'center'
                              ,backgroundSize:'cover' 
                            }}
                            className={`flex items-center content-center cursor-pointer w-full sm:w-20  h-16 sm:h-20  
                            ${isPlaying
                              ? ''// `bg-red-600 hover:bg-red-500 text-white`
                              : '' //`bg-gray-500 hover:bg-gray-400 text-white ` //
                            }
                                        `}
                          onClick={()=> {
                            playerControls();
                            set_isShowSeek(true);
                          }}
                          >
                            
                            <div className={`
                              text-white flex-1 w-full sm:w-20 h-16 sm:h-20  flex items-center content-center justify-center
                              ${isPlaying
                                ? 'bg-[#00000055] hover:bg-[#00000033]'
                                : 'bg-[#00000099] hover:bg-[#00000077]'
                              }
                              `}>
                            
                            <div
                              title={player_state?.isEnded && isPlaying
                                ? "Restart recording"
                                : player_state?.isPlaying && isPlaying
                                  ? "Pause"  
                                  : "Play" 
                              }

                              
                            >
                            
                                <SvgJsx 
                                    type={'fill'}
                                    icon={player_state?.isEnded && isPlaying
                                            ? "refresh-sm"
                                            : player_state?.isPlaying  && isPlaying
                                              ? "pause-sm" 
                                              : "play-sm"
                                          }
                                    className={`h-12 w-12  mx-auto rounded-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)]`}
                                    title={!player_state?.isPlaying ? "play" : "pause"}
                                />
                            
                              
                            </div>
                              
                            
                            </div>
                            
                          </div>
                        </div>
                      </>
                      }



                    {/* PLACEHOLDER */}
                    {display_data?.bol_status?.toString() == "-1" &&
                      <>
                      <div className={`h-16 flex items-center content-center justify-center w-full rounded-t-md
                              bg-gray-600  text-white group
                                  
                                    `}
                                    onClick={()=> {
                                    alert(textProcess('Still loading...'))
                                    }}     
                                    title={textProcess(`Loading`)}       
                        >
                        <div className="flex-1 mx-auto justify-center flex  ">
                        
                        
                          
                        
                          <LoadingRotary 
                              classSize={`w-12 h-12 mx-auto block`}
                              classAnimate='animate-spin-slow'
                              bgColor='#00000000'
                              centerColor='#000000'
                              pointerColor='#c0c0c0'
                              holeColor='#000000aa'
                              dialerColor='#c0c0c0aa'
                              size='64'
                          />
                        
                        </div>
                        
                      </div>
                      </>
                      }

            </div>

            <div className="flex items-top content-top w-full flex-1">

              {/* LEFT COL */}
              <div className="flex-1 flex-items-center content-center">

                {/* INFO CONTAINER */}
                {!isShowSeek &&
                <div className="flex-1 ml-4 mr-2"

                >

                    {/* TOP ROW */}
                    <div className="flex items-center content-center"
                     
                    >

                          {/* TITLE */}
                          <div className="flex-1 flex items-top content-top text-left w-24 xs:w-48 overflow-hidden truncate">
                             <div className="flex-1">
                               <Link href={permaurl}>

                                 <h3 className={`my-2 sm:my-0 text-sm sm:text-lg font-bold `}>
                                   {textProcess(hasData(display_data?.broadcast_title) ? display_data?.broadcast_title : `Untitled show from ${friendlydate(new Date(display_data?.start_date),false)}`)}
                                 </h3>

                               </Link>
                            </div>
                            <div>
                            {isPlaying && !isShowSeek &&
                              <div 
                                className={`flex-0 mx-2 flex font-bold p-0 cursor-pointer 
                                          
                                          text-gray-600 hover:text-gray-400`}
                                onClick={()=>{

                                  set_isShowSeek(!isShowSeek);
                                  
                                  }
                                }
                              >
                                <div className="flex-0 flex ">
                                    
                                    <div className="flex-0">
                                      <SvgJsx 
                                          type={isShowSeek ? 'fill' : "outline"}
                                          icon={isShowSeek ? "x-sm" : "adjustments-single"}
                                          className={isShowSeek ? `h-4 w-4  ` : `h-6 w-6 mt-4`}
                                          title={"close"}
                                      />
                                    </div>
                                    
                                  </div>
                              </div>
                              }
                            </div>
                          </div>





                    </div>

                    {/* BOTTOM ROW */}
                    <div className="flex items-center content-center text-left">

                      {/* USERNAME */}
                      <div className="flex-0 flex items-center content-center text-left">

                        <Link href={`/${display_data?.user_name?.toLowerCase()}`} >

                          <AuthorCredit 
                            display_data={display_data}
                            isPlaying={(player_state?.isPlaying && isPlaying)}
                          />

                        </Link>
                          

                          
                          {IsJsonValid(display_data?.guests_json) && JSON.parse(display_data?.guests_json || '[]')?.length > 0 && 
                          <div className="flex-0 ml-1 cursor-pointer hover:opacity-75"
                          onClick={()=>openModal(display_data?.content_id,"info")}
                          >
                            +{JSON.parse(display_data?.guests_json || '[]')?.length}
                          </div>}

                          <div className="block
                          flex-0  pl-2 border-l ml-2 border-gray-700 text-xs sm:text-sm ">
                          {timerFormat(countUpBySeconds(Math.ceil(display_data?.duration)))}
                          </div>

                          {/* AIR DATE */}
                          <div className="block
                          flex-0  pl-2 border-l ml-2 border-gray-700 text-xs sm:text-sm">
                            
                            <ChatTimer
                                start_date={new Date(display_data?.start_date)?.toString()}
                                timestamp={(display_data?.timestamp)?.toString()}
                                time={0}
                            />
                          </div>

                        </div>

                        


                    </div>



                </div>
                }

                {/* SEEK CONTAINER  */}
                {isShowSeek &&
                <div className="flex flex-1 items-center content-center w-full my-2">
                  {/* SEEK CONTENT */}
                  <div className="flex-1 ml-4 mr-2">

                      {/* TOP ROW */}
                      <div className="flex items-center content-center">


                      {/* LIVE SEEK */}
                      {(display_data?.bol_status?.toString() =="2") &&
                        <div className=" flex-1 flex items-center content-center font-thin mt-2">



                            {/* SEEK */}
                            <div className="flex-1 ">

                          
                                    <div className="flex items-center content-center h-4 px-1 ">

                                      
                                      <div className="flex-1 text-sm ">
                                      <Slider
                                              axis='x'
                                              x={(player_state?.seek_slider)}
                                              xmax={100}
                                              //disabled={player_state?.isPlaying}
                                              onChange={({ x }) => {
                                                      doSeekbyRatio(x)
                                                  }}
                                              
                                              styles={{
                                                      active: {
                                                          backgroundColor: player_state?.isPlaying
                                                                          ? '#0000ff'
                                                                          : '#0000ff'
                                                          
                                                      
                                                      },
                                                      track: {
                                                          backgroundColor: '#555555',
                                                          display: 'flex',
                                                          height: 16,
                                                          width: '100%',
                                                          cursor: 'pointer',
                                                          padding: '0px 0px'
                                                      },
                                                      thumb: {
                                                          width: 24,
                                                          height: 24,
                                                          backgroundColor: !player_state?.isPlaying
                                                                          ? '#ffffff'
                                                                          : '#ffffff',
                                                          border: '1px solid #a0a0a0'
                                                      },
                                                      
                                                  }}
                                              />
                                      </div>
                                      
                                      
                                    </div>
                                    
                              
                            </div>

                            

                        </div>
                        }




                      </div>

                      {/* BOTTOM ROW */}
                      <div className="flex items-center content-center mt-2">
                        
                            {/* CURRENT TIME */}
                            <div className="flex-0 ml-1 w-16 text-left">
                            {timerFormat(countUpBySeconds(Math.ceil(player_state?.seek)))}
                            </div>
                            <div className="flex-1">

                            </div>
                            {/* TOTTAL TIME */}
                            <div className="flex-0 mr-1 w-16 text-right">
                              {timerFormat(countUpBySeconds(Math.ceil(display_data?.duration)))}
                            </div>
                        
                      </div>

                  </div>


                </div>
                }

              </div>

              {/* RIGHT COL */}

              {/* Collapse Seek */}
              {isPlaying && isShowSeek &&
              <div 
                className={`flex-0 mx-2 flex font-bold p-0 cursor-pointer mt-4
                          
                          text-gray-600 hover:text-gray-400`}
                onClick={()=>{

                  set_isShowSeek(!isShowSeek);
                  
                  }
                }
              >
                <div className="flex-0 flex ">
                    
                    <div className="flex-0">
                      <SvgJsx 
                          type={isShowSeek ? 'fill' : "outline"}
                          icon={isShowSeek ? "x-sm" : "adjustments-single"}
                          className={isShowSeek ? `h-4 w-4  ` : `h-6 w-6 mt-4`}
                          title={"close"}
                      />
                    </div>
                    
                  </div>
              </div>
              }

            </div>


                    
            
          </div>



        </div>
        

        



      </div>
    );

   
}

  